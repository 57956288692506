import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Container, Flex } from '@chakra-ui/react';
import { GetBusinessMetadataInput, GetBusinessPromotedScheduleQuery } from 'API';
import { getBusinessPromotedScheduleFn } from 'api/query-functions/business';
import { useGetBusinessPromotedSchedule } from 'api/query-hooks/business';
import AppLayout from 'components/AppLayout';
import { PageRoutes } from 'constants/Routes';
import OrderTypeLinks from 'containers/About/OrderTypeLinks';
import { useAppContext } from 'context/AppContext';
import { CustomPromotedSchedule, ICommonAppProps } from 'custom-types';
import useMount from 'hooks/useMount';
import { QueryKeys } from 'models/react-query';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { dehydrate } from 'react-query';
import { fetchQueryHelper, ssrAuthHandler, wrapGetServerSideProps } from 'ssr';

const HomeFeaturedProducts = dynamic(() => import('containers/Home/HomeFeaturedProducts'));
const HomeHowItWorksSection = dynamic(() => import('containers/Home/HomeHowItWorksSection'));
const HomeSubscribeAndSaveSection = dynamic(
  () => import('containers/Home/HomeSubscribeAndSaveSection')
);
const TestimonialSection = dynamic(() => import('containers/Home/TestimonialSection'));
const HomeHeroSection = dynamic(() => import('containers/Home/HomeHeroSection'));

const Home = ({ businessMetadata }: ICommonAppProps) => {
  const { mounted } = useMount();
  const { businessId } = useAppContext();
  const router = useRouter();

  const {
    howItWorks,
    testimonials,
    showPromotePlans,
    pathSegments,
    coverImage,
    headline,
    featuredProducts,
    promotePlansText,
  } = businessMetadata;

  const promotedScheduleQuery = useGetBusinessPromotedSchedule(
    { businessId },
    { refetchInterval: 60000 }
  );
  if (promotedScheduleQuery.isLoading) {
    return <></>;
  }

  if (promotedScheduleQuery.isError || !promotedScheduleQuery.data) {
    return router.push(PageRoutes.SERVER_DOWN);
  }

  const promotedSchedule = promotedScheduleQuery.data.data?.getBusinessMetadata?.promotedSchedule;

  let customPromotedSchedule: CustomPromotedSchedule | undefined;
  if (promotedSchedule) {
    customPromotedSchedule = {
      id: promotedSchedule.id,
      pathSegment: promotedSchedule.location?.pathSegment,
      type: promotedSchedule.type,
      orderCutoff: promotedSchedule.orderCutoff,
      orderReadyEnd: promotedSchedule.orderReadyEnd,
      pickupAddressId: promotedSchedule.pickupAddress?.id,
    };
  }

  return (
    <AppLayout showChangeLocationOption={false} showNavigationLinks showFooter showBanner>
      <HomeHeroSection
        coverImage={coverImage}
        pathSegments={pathSegments}
        primaryText={headline.primary}
        secondaryText={headline.secondary}
        promotedSchedule={customPromotedSchedule}
      />
      <Container maxW="1440px">
        {mounted && featuredProducts.length ? (
          <HomeFeaturedProducts featuredProducts={featuredProducts} />
        ) : null}

        <HomeHowItWorksSection howItWorks={howItWorks} />

        {showPromotePlans && <HomeSubscribeAndSaveSection promotePlansText={promotePlansText} />}

        {testimonials.length >= 0 ? (
          <TestimonialSection testimonials={testimonials} />
        ) : (
          <Flex py={{ base: 8, md: '16' }}></Flex>
        )}

        <Flex mb="16">
          <OrderTypeLinks
            pathSegments={businessMetadata.pathSegments}
            title={businessMetadata.headline.primary}
          />
        </Flex>
      </Container>
    </AppLayout>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps<ICommonAppProps> = wrapGetServerSideProps(
  'Home',
  async (context) => {
    using ssrOutput = await ssrAuthHandler(context, false);
    const {
      cognito,
      queryClient,
      businessId,
      consumerId,
      locationPairs,
      businessMetadata,
      isLoginRequired,
      SSR,
    } = ssrOutput.pageContext;

    await fetchQueryHelper<
      GraphQLResult<GetBusinessPromotedScheduleQuery>,
      GetBusinessMetadataInput
    >(
      [QueryKeys.BUSINESS_PROMOTED_SCHEDULE, { businessId }],
      getBusinessPromotedScheduleFn,
      queryClient,
      SSR.API
    );

    return {
      props: {
        businessId,
        businessMetadata,
        cognito,
        dehydratedState: dehydrate(queryClient),
        locationPairs,
        consumerId: consumerId ?? null,
        metadata: { isLoginRequired },
      } satisfies ICommonAppProps,
    };
  }
);
